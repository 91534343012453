import AddIcon from '@mui/icons-material/Add'
import DownloadingIcon from '@mui/icons-material/Downloading'
import { Stack } from '@mui/material'
import React, { FC, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetProjectByIdQuery } from '../../../../api/projects'
import { useGetGroupedWorksQuery } from '../../../../api/works'
import EmptyPage from '../../../../components/EmptyPage'
import { EmptyPageData } from '../../../../components/EmptyPage/EmptyPage.types'
import Progress from '../../../../components/Progress'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../hooks/useConfirmDialog'
import { Limit } from '../../../../types/global'
import { NUMBER_OF_ROWS_PER_WORKS_PAGE } from '../../../../utils/constants'
import { getEmptyPageData } from '../../../Home'
import { WorksBottomLegend } from '../WorksBottomLegend'
import { INumbersOfsubGroup, WorksDrawer } from '../WorksDrawer'
import { WorksTableView, WorksTable } from '../WorksTable'
import { WorksFiltersData, WorksFiltersDataFields, WorksTopLegend } from '../WorksTopLegend'
import { WorksUploadDrawer } from '../WorksUploadDrawer'

export const Works: FC = () => {

  const { projectId: projectIdStr, scheduleId: scheduleIdStr } = useParams()
  const projectId = parseInt(projectIdStr!)
  const scheduleId = parseInt(scheduleIdStr!)

  const { data: projectData } = useGetProjectByIdQuery(
    { id: projectId }, { skip: !projectId }
  )

  const { data: currentProject } = projectData || {}

  const [chosenWorkId, setChosenWorkId] = useState<number | null>(null)
  const [openedDrawer, setOpenedDrawer] = useState<'work' | 'upload' | null>(null)
  const [tableView, setTableView] = useState<WorksTableView>('months')
  const [filterData, setFilterData] = useState<WorksFiltersData>({
    ids: [],
    name: [],
    status: [],
  })

  const onFilterChange = <T extends WorksFiltersDataFields>(filterType: T, value: WorksFiltersData[T]) => {
    setFilterData(prevState => ({
      ...prevState,
      [filterType]: value,
    }))
  }

  const onAddClick = useCallback(() => {
    setOpenedDrawer('work')
  }, [])

  const onRowClick = useCallback((workId: number) => {
    setChosenWorkId(workId)
    setOpenedDrawer('work')
  }, [])

  const onUploadClick = useCallback(() => {
    setOpenedDrawer('upload')
  }, [])

  const onTableViewChange = useCallback((value: WorksTableView) => {
    value !== tableView && setTableView(value)
  }, [tableView])


  // Pagination
  const [limitValue, setLimitValue] = useState<Limit['value']>(
    NUMBER_OF_ROWS_PER_WORKS_PAGE[1].value
  )
  const [page, setPage] = useState(1)

  const offset = useMemo(() => limitValue * (page - 1), [limitValue, page])

  const { data: groupedWorksData, isLoading } = useGetGroupedWorksQuery({
    id: projectId,
    scheduleId,
    limit: limitValue,
    offset: offset,
    ...filterData,
  })
  const { data: groupedWorks = [], dataExist: worksExist, entirePeriod, overallRatio, total = 0 } = groupedWorksData || {}

  const countPagination = useMemo(
    () => Math.ceil(total / limitValue) || 1,
    [total, limitValue]
  )

  const handleChangeLimit = useCallback(
    (limit: number) => {
      setLimitValue(Number(limit))
      setPage(1)
    },
    [setLimitValue, setPage]
  )
  const handleChangePage = useCallback((page: number) => setPage(page), [setPage])


  // Utility
  const clearDataOnDrawerClose = useCallback(() => {
    setOpenedDrawer(null)
    setChosenWorkId(null)
  }, [])
  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      clearDataOnDrawerClose()
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    if (immediately || !dirty) {
      clearDataOnDrawerClose()
    } else {
      openConfirm()
    }
  }, [])


  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>В графике еще не создано ни одной работы. <br />
      Вы можете добавить работу через форму или загрузить подготовленный список работ с помощью excel-файла.</>,
    [
      {
        text: 'Добавить работу',
        icon: AddIcon,
        onClick: onAddClick,
      },
      {
        text: 'Импорт excel',
        icon: DownloadingIcon,
        onClick: onUploadClick,
      }
    ]
  )

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют работы, соответствующие результатам запроса.</>
  )

  const numbersOfsubGroup = useMemo((): INumbersOfsubGroup[] => {
    if (!groupedWorks) return []
    return groupedWorks.map((group) => ({
      nameGroup: group.group,
      numGroup: group.numGroup,
      numSubgroup: group.works[group.works.length - 1].numWork
    }))
  }, [groupedWorks])

  return (
    <Stack flex={1} maxWidth='100%'>
      {isLoading
        ? <Progress />
        : worksExist
          ? (
            <>
              <WorksTopLegend
                currentProject={currentProject!}
                onAddClick={onAddClick}
                onUploadClick={onUploadClick}
                filterData={filterData}
                onFilterChange={onFilterChange}
              />

              <WorksBottomLegend
                tabValue={tableView}
                onTabChange={onTableViewChange}
                countPagination={countPagination}
                limit={limitValue}
                onChangeLimit={handleChangeLimit}
                onChangePage={handleChangePage}
                page={page}
                numberRows={NUMBER_OF_ROWS_PER_WORKS_PAGE}
              />

              {groupedWorks?.length
                ? (
                  <WorksTable
                    data={groupedWorks}
                    onRowClick={onRowClick}
                    tableView={tableView}
                    entirePeriod={entirePeriod!}
                    overallRatio={overallRatio!}
                  />
                )
                : <EmptyPage data={emptyFilteredData} forFilter />
              }
            </>
          )
          : <EmptyPage data={emptyPageData} fullPage backButton />
      }

      <WorksDrawer
        open={openedDrawer === 'work'}
        onClose={onDrawerClose}
        chosenWorkId={chosenWorkId}
        numbersOfsubGroup={numbersOfsubGroup}
      />
      <WorksUploadDrawer
        open={openedDrawer === 'upload'}
        onClose={onDrawerClose}
        projectId={projectId}
        scheduleId={scheduleId}
      />
      <ConfirmDialog />
    </Stack>
  )
}
